html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black; /* Set the background color here */
}

body > div {
  height: 100%;
}

.header {
  text-align: center;
  font-family: 'Press Start 2P', monospace;
  background-color: black;
  color: rgb(130, 224, 130);
  min-height: 120%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header h1{
  text-shadow: 0.5px 0.5px rgb(40, 86, 43), 1px 1px limegreen, 1.5px 1.5px limegreen; /* 3D effect */
  color: rgb(130, 224, 130);
}

.education-line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.education-line p {
  line-height: 1.75; /* Adjusts the space between lines */
  text-shadow: 0.5px 0.5px rgb(40, 86, 43), 1px 1px limegreen, 1.5px 1.5px limegreen; /* 3D effect */
  color: rgb(130, 224, 130);
  padding:10%
}
.third-line p {
  line-height: 1.75; /* Adjust this value as needed */
}
.header p {
  text-shadow: 0.5px 0.5px rgb(40, 86, 43), 1px 1px limegreen, 1.5px 1.5px limegreen; /* 3D effect */
  color: rgb(130, 224, 130);
  
  padding: 0.75%; 
}


.tiger-icon{
  width: 9%; /* or adjust as needed */
  height: auto;
  margin-left: 5px;
}
.triton-icon {
  width: 5%; /* or adjust as needed */
  height: auto;
  margin-left: 5px;
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; 
  align-items: center;
}

.icon-button {
  text-decoration: none;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  margin: 0 0.5%;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.3s ease;
  color: rgb(130, 224, 130);
}

.icon-button:hover {
  transform: translateY(-5px);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
}


@media only screen and (max-width: 768px) {
  .icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .icon-button {
    max-width: 45%;
    box-sizing: border-box;
  }

  .tiger-icon{
    width : 25%;
  }
  .triton-icon {
    width: 20%; /* or adjust as needed for mobile screens */
  }
}
