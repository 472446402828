
.app-container {
  position: relative;
  overflow: hidden; /* Hide the pages that are off the viewport */
  width: 100%;
  height: 100vh; /* Adjust this based on your desired height */
  font-family: 'Press Start 2P', monospace;
}

.swipe-area {
  position: relative;
  width: 100%;
  height: 100%;
}

.pages {
  display: flex; /* Arrange the pages horizontally */
  width: 300%; /* 100% times the number of pages */
  height: 100%;
}

.page {
  flex: 1; /* Equal width for each page */
  width: 100%;
  height: 100%;
  overflow: hidden; /* Hide overflowing content */
}

/* Additional styling for the buttons */
button {
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}
nav {
  display: flex;
  justify-content: center;
  background-color: black;
  padding: 10px;
}

nav a {
  color: limegreen;
  text-decoration: none;
  margin: 0 15px;
  font-family: 'Press Start 2P', monospace;
}

nav a:hover {
  text-decoration: underline;
}

.page-content {
  text-align: center;
  font-family: 'Press Start 2P', monospace;
  background-color: black;
  color: limegreen;
  padding: 20px;
}
.navigation {
  position: fixed;
  bottom: 20px; /* Adjust based on your preference */
  right: 20px;
  display: flex;
  flex-direction: row; /* Align horizontally */
  gap: 10px; /* Add space between the buttons */
}

.navigation a {
  color: white; 
  text-decoration: none;
  background-color: #333;
  padding: 15px;
  border-radius: 50%;
  width: 60px; /* Adjust the size */
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.navigation a p {
  margin: 0;
  font-size: 10px; /* Adjust the size */
}

.navigation a:hover {
  background-color: limegreen; 
}

/* Additional styling for mobile screens */
@media only screen and (max-width: 768px) {
  .navigation {
    bottom: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
  }
}


/* You may also include styles from the previous Header.css to maintain the look and feel */
