.about-me {
  background-color: black;
  color: rgb(130, 224, 130);
  font-family: 'Press Start 2P', monospace;
  padding: 20px;
  text-align: center;
}

.about-me h2 {
  margin-bottom: 15px;
}

.about-me p {
  margin-bottom: 10px;
  line-height: 1.5;
}
