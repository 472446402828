.projects {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust grid layout */
  gap: 20px; /* Space between grid items */
}

.project-box {
  position: relative;
  text-align: center;
  padding: 10px;
  border: 2px solid transparent;
}

.project-box:hover {
  border-color: limegreen; /* Border color on hover */
}

.project-icon {
  width: 100px; /* Adjust as needed */
  height: auto;
  display: block;
  margin: 0 auto;
}

.project-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8); /* Background of the popup */
  color: limegreen; /* Text color in the popup */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-name {
  font-family: 'Press Start 2P', monospace;
  color: limegreen;
}
